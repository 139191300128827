.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.logo img{
  height: 40px;

  margin: 17px 36px;
}
.logo {
  width: 200px;
background: #fff;

}
.ag-theme-material {
  height: 550px;
}

.ant-layout-sider-trigger {
  display: none;
}
.queue {
  background-color: lightgray !important;
}
.accepted {
  background-color: lightgreen !important;
}
.rejected {
  background-color: indianred !important;
}